import {Component, PLATFORM_ID, Inject, OnInit, OnDestroy} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {  Router } from '@angular/router';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {TokenStorageService} from './shared/services/token.service';
import {EventBusService} from './shared/event-bus.service';
import { ReactiveFormConfig } from "@rxweb/reactive-form-validators";
import {JwtHelperService} from '@auth0/angular-jwt';
import { AuthService } from './shared/services/auth.service';
import { CryptDataService } from './shared/services/crypt-data.service';
import { DemandeDevisService } from './pages/operations/demande-devis/demande-devis.service';
import { DemandeReservationsService } from './pages/operations/demande-reservations/demande-reservations.service';
import { SharedDataService } from './shared/services/shared-data.service';
declare var require
const Swal = require('sweetalert2');
import Pusher from 'pusher-js';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private roles: string[] = [];
    public length_reservation =null;
    public length_devis = null;
    public timer_reservation = null;
    public timer_devis = null;
    public length_devis_entreprise = null;
    isLoggedIn = false;
    showAdminBoard = false;
    showModeratorBoard = false;
    username?: string;

    eventBusSub?: Subscription;
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService, private tokenStorageService: TokenStorageService,
              private eventBusService: EventBusService, private jwtHelper: JwtHelperService, private auth : AuthService, private crypt : CryptDataService, private reservation : DemandeReservationsService, private devis : DemandeDevisService, private shareDataService : SharedDataService, private router : Router) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    }
  }

  ngOnInit(): void {
        this.isLoggedIn = !!this.tokenStorageService.getToken();
        
        
        if (this.crypt.getData('token')&&this.jwtHelper.isTokenExpired(this.crypt.getData('token').replaceAll('"', ""))) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false,
        })
          swalWithBootstrapButtons.fire({
            title: 'Votre session a expiré',
            html: `Veuillez vous reconnectez`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        }).then(result=>{
          this.auth.signOut()
        })
        } 
        this.eventBusSub = this.eventBusService.on('logout', () => {
            this.logout();
        });
        ReactiveFormConfig.set({
            "internationalization": {
                "dateFormat": "dmy",
                "seperator": "/"
            },
            "validationMessage": {
                "alpha": "Seuls les alphabets sont autorisés.",
                "alphaNumeric": "Seuls l'alphabet et les chiffres sont autorisés.",
                "compare":"Les entrées ne sont pas appariées.",
                "contains":"value is not contains in the input",
                "creditcard":"creditcard number is not correct",
                "digit":"Seuls les chiffres sont autorisés",
                "email":"L'email n'est pas valide",
                "greaterThanEqualTo":"please enter greater than or equal to the joining age",
                "greaterThan":"please enter greater than to the joining age",
                "hexColor":"please enter hex code",
                "json":"please enter valid json",
                "lessThanEqualTo":"please enter less than or equal to the current experience",
                "lessThan":"please enter less than or equal to the current experience",
                "lowerCase":"Only lowercase is allowed",
                "maxLength":"maximum length is 10 digit",
                "maxNumber":"enter value less than equal to 3",
                "minNumber":"enter value greater than equal to 1",
                "password":"please enter valid password",
                "pattern":"please enter valid zipcode",
                "range":"please enter age between 18 to 60",
                "required":"Ce champ est requis",
                "time":"Only time format is allowed",
                "upperCase":"Only uppercase is allowed",
                "url":"Only url format is allowed",
                "zipCode":"enter valid zip code",
                "minLength":"minimum length is 10 digit"
            }
        });
        const pusher = new Pusher("39ba1d3f073665117dbb",{cluster : "eu"});
            const channel = pusher.subscribe('monartisan-development');
            channel.bind(`/push/new/reservation`, (data)=>{
              this.getDemandeReservation()
            })
            channel.bind(`/push/new/devis`, (data)=>{
              this.getDemandeDevis()
            })
        // this.getDemandeDevis()
        
    }

    ngOnDestroy(): void {
      clearInterval(this.timer_devis)
      clearInterval(this.timer_reservation)
        if (this.eventBusSub)
            this.eventBusSub.unsubscribe();
    }

    logout(): void {
    }

    getDemandeReservation(){
      let audio = new Audio('./assets/alert-tone.mp3');
      let playPromise =  audio.play();
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
    })
      swalWithBootstrapButtons.fire({
          title: 'Vous avez reçu une nouvelle demande de reservation',
          icon: 'info',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
      }).then(()=>{
        audio.pause();
        audio.currentTime = 0;
        this.shareDataService.sendRefreshListDemandeReservation()
      })
      
      setTimeout(()=>{
        audio.pause();
        audio.currentTime = 0;
        this.shareDataService.sendRefreshListDemandeDevis()
      },30000)
    }
    
    getDemandeDevis(){
      let audio = new Audio('./assets/alert-tone.mp3');
      let playPromise =  audio.play();
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
    })
      swalWithBootstrapButtons.fire({
          title: 'Vous avez reçu une nouvelle demande de devis',
          icon: 'info',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
      }).then(()=>{
        audio.pause();
        audio.currentTime = 0;
        this.shareDataService.sendRefreshListDemandeDevis()
      })
      
      
                                
      
    }

    

}
