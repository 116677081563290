import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

// Menu
export interface Menu {
  selectable?: boolean;
  collapsedIcon?: string;
  expandedIcon?: string;
  data?: string;
  permission?: string;
  label?: string;
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {

  constructor(private router: Router, private storage: LocalStorageService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
      });
    }
  }
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );
  public user: any;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen = false;

  MENUADMIN: Menu[] = [
    {
      title: 'Tableau de bord',
      path: '/dashboard',
      icon: 'home',
      type: 'link',
    },
    {
      headTitle1: 'Modules de gestion',
      active: false,
    },
    {
      title: 'Demande de devis',
      path: 'operations/demande-devis',
      icon: 'file',
      type: 'link',
    },
    {
      title: 'Réservations',
      path: 'operations/demande-reservations',
      icon: 'calendar',
      type: 'link',
    },
    {
      title: 'Répertoire',
      active: false,
      icon: 'database',
      type: 'sub',
      children: [
        {
          path: '/repertoire/artisans',
          title: 'Artisans',
          type: 'link',
        },
        {
          path: '/repertoire/type-devis',
          title: 'Clients',
          type: 'link',
        },
        // {
        //   path: '/repertoire/fournisseurs',
        //   title: 'Fournisseurs',
        //   type: 'link',
        // },
        // {
        //   path: '/repertoire/materiels',
        //   title: 'Materiels',
        //   type: 'link',
        // },
      ],
    },
    {
      title: 'Factures & Bons',
      bookmark: true,
      icon: 'file-text',
      type: 'sub',
      children: [
        {
          path: '/facture/facture',
          title: 'Factures',
          type: 'link',
        },
        {
          path: '/facture/devis',
          title: 'Devis',
          type: 'link',
        },
        {
          path: '/facture/bon-entree',
          title: 'Bons d\'entrée',
          type: 'link',
        },
        {
          path: '/facture/bon-sortie',
          title: 'Bons de sortie',
          type: 'link',
        },
      ],
    },
    {
      title: 'Messages',
      icon: 'mail',
      type: 'sub',
      active: false,
      children: [
        {
          path: '/mailing/messages-internautes',
          title: 'Messages internautes',
          type: 'link',
        },
        {
          path: '/mailing/demandes-artisans',
          title: 'Demandes artisans',
          type: 'link',
        }
      ],
    },
    {
      title: 'Entreprise',
      icon: 'briefcase',
      type: 'sub',
      active: false,
      children: [
        {
          title: 'Abonnements',
          path: '/entreprise/abonnements',
          type: 'link',
        },
        {
          title: 'Forfaits de pose',
          path: '/entreprise/forfait-pose',
          type: 'link',
        },
        {
          title: 'Dépannages',
          path: '/entreprise/depannage',
          type: 'link',
        },
        // {
        //   title: 'Projets rénovation',
        //   path: '/entreprise/projet-renovation',
        //   type: 'link',
        // },
        {
          title: 'Devis entreprises',
          path: '/entreprise/devis-entreprise',
          type: 'link',
        },
        {
          title: 'Besoins entreprises',
          path: '/entreprise/besoins-entreprise',
          type: 'link',
        },
      ],
    },
    {
      headTitle1: 'Administration',
      active: false,
    },
    {
      title: 'Paramètres',
      icon: 'settings',
      type: 'sub',
      active: false,
      children: [

        {
          title: 'Categorie prestation',
          path: '/settings/types-reservations',
          type: 'link',
        },
        {
          title: 'Code promo',
          path: '/settings/code-promo',
          type: 'link',
        },
        {
          title: 'Publicité',
          path: '/settings/pub',
          type: 'link',
        },
        {
          title: 'Comptes utilisateurs',
          path: '/settings/utilisateurs',
          type: 'link',
        },
        {
          title: 'Comptes administrateurs',
          path: '/settings/administrateurs',
          type: 'link',
        }
      ],
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUADMIN);

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }
}
